import React, { Component } from 'react'
import { createStyles, withStyles, Button } from '@material-ui/core'

const styles = () => {
  return createStyles({
    button: {
      width: '8.8125rem',
      height: '2.8125rem',
      padding: 0,
      cursor: 'pointer'
    },
    image: {
      width: '100%',
    },
  })
}

class DownloadButton extends Component {
  render() {
    const { classes, src, alt } = this.props
    return (
      <div className={classes.button}>
        <img src={src} alt={alt} className={classes.image} />
      </div>
    )
  }
}

export default withStyles(styles)(DownloadButton)
