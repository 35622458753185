import React, { Component } from 'react'

import Option from './Option'
import DownloadButton from './Option/DownloadButton'
import comingSoon from '../../images/coming-soon.png'

class DownloadGooglePlay extends Component {
  render() {
    return (
      <DownloadButton src={comingSoon} alt="Download on the google play store" />
    )
  }
}

export class AppStore extends Component {
  render() {
    return (
      <Option title="Android" button={<DownloadGooglePlay />}>
        Download Avocado Finance from the Play Store to access it on your
        Android.
      </Option>
    )
  }
}

export default AppStore
