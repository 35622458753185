import React, { Component } from 'react'

import Option from './Option'
import DownloadButton from './Option/DownloadButton'
import availableOnApple from '../../images/availableOnApple.png'

class DownloadAppStore extends Component {
  render() {
    return <DownloadButton src={availableOnApple} alt="Download on the app store" />
  }
}

export class AppStore extends Component {
  render() {
    return (
      <a
        target='_blank'
        href='https://avocadofinance-alternate.app.link'
        style={{
          margin: 0,
          padding: 0,
          textDecoration: 'none',
        }}
      >
        <Option title="iPhone" button={<DownloadAppStore />}>
          Download Avocado Finance from the App Store to access it on your iPhone.
        </Option>
      </a>
    )
  }
}

export default AppStore
