import React, { Component } from 'react'
import Head from 'react-helmet'

import Layout from '../components/Layout'
import Hero from './Hero'
import Options from './Options'

export class Download extends Component {
  render() {
    return (
      <Layout>
        <Head>
          <title>Download</title>
        </Head>
        <Hero />
        <Options />
      </Layout>
    )
  }
}

export default Download
