import React, { Component } from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import forecastImage from '../iphone.png'

import androidImage from '../android.png'


const styles = (theme) => {
  return createStyles({
    item: {
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '80px',
      [theme.breakpoints.up('sm')]: {
        flexBasis: '21.6875rem',
      },
      '& + &': {
        marginTop: '10.75rem',
        [theme.breakpoints.up('sm')]: {
          marginTop: 0,
        },
      },
    },
    image: {
      width: '7.625rem',
      height: '15.5625rem',
      marginBottom: '2.5rem',
    },
    title: {
      marginBottom: '2.5rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.875rem',
        fontWeight: 700,
      },
    },
    description: {
      marginBottom: '2.25rem',
      fontSize: '1rem',
      [theme.breakpoints.up('sm')]: {
        width: '310px',
      },
    },
  })
}

class Option extends Component {
  render() {
    const { classes, title, children, button } = this.props
    return (
      <li className={classes.item}>
        <img
          src={title === 'Android' ? androidImage : forecastImage}
          alt="Forecast page"
          className={classes.image}
        />
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
        <Typography className={classes.description}>{children}</Typography>
        {button}
      </li>
    )
  }
}

export default withStyles(styles)(Option)
