import React, { Component } from 'react'
import { Container, createStyles, withStyles } from '@material-ui/core'

import AppStore from './AppStore'
import GooglePlay from './GooglePlay'
import { pagePaddingX } from '../styles'

const styles = (theme) =>
  createStyles({
    container: {
      maxWidth: '50.8125rem',
      padding: `0 ${pagePaddingX}`,
      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },
    list: {
      paddingLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
    },
  })
class Options extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container className={classes.container}>
        <ul className={classes.list}>
          <AppStore />
          <GooglePlay />
        </ul>
      </Container>
    )
  }
}

export default withStyles(styles)(Options)
