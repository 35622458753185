import React, { Component } from 'react'
import {
  Container,
  createStyles,
  withStyles,
  Typography,
} from '@material-ui/core'

import { getHeroStyles } from '../styles/hero'
import { pagePaddingX } from './styles'

const styles = (theme) => {
  const heroStyles = getHeroStyles()
  const titleMarginX = 'auto'
  return createStyles({
    section: {
      ...heroStyles,
      marginBottom: '5.8125rem',
      padding: `0 ${pagePaddingX}`,
      [theme.breakpoints.up('sm')]: {
        marginBottom: '5.5625rem',
        padding: 0,
      },
    },
    title: {
      color: '#F0EDEF',
      maxWidth: '16.625rem',
      marginLeft: titleMarginX,
      marginRight: titleMarginX,
      [theme.breakpoints.up('sm')]: {
        letterSpacing: '0.03em',
        maxWidth: '49.875rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.25rem',
      },
    },
    titleBottom: {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  })
}

class Hero extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container className={classes.section}>
        <Typography className={classes.title} variant="h3" align="center">
          Avocado Finance is available on iOS
          {/* <span className={classes.titleBottom}>iOS and Android</span> */}
        </Typography>
      </Container>
    )
  }
}

export default withStyles(styles)(Hero)
